<template>
  <div class="public-video">
    <div class="public-video-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.channelId"
            style="width: 260px"
            placeholder="请输入频道ID"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model.trim="searchForm.inputKey"
            style="width: 452px"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="searchArticlePage"
          >
            检索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】可以通过上面手动输入关键词或频道id点击检索进行查询，也可以使用自己定义的关键词组点击查询进行查询
              </p>
              <p>
                【2】点击下方视频可进入详情页，视频详情页中可以点击“重载评论”来获取最新评论,“收藏”，可以将视频收藏到“我的收藏”中，以防想再看这个视频的时候找不到
              </p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>

    <div v-loading="loading" class="public-video-content">
      <div class="video-left">
        <div class="pagination-box">共检索到数据{{ total }}条</div>
        <div
          class="video-left-content"
          v-infinite-scroll="getVideoList"
          :infinite-scroll-disabled="scrollDisabled"
          :infinite-scroll-distance="10"
        >
          <div class="content-item" v-for="(item, i) in listData" :key="i">
            <div class="content-item-left">
              <img :src="item.videoThumbnail" alt="" />
            </div>
            <div class="content-item-right">
              <div class="content-item-title">
                <div
                  class="title"
                  v-html="item.title"
                  @click="lookNews(item)"
                ></div>
              </div>
              <div class="content-item-time">
                {{ item.viewCount }}次观看 &nbsp;{{ item.publishDate }}
              </div>
              <div class="content-item-bottom">
                <img
                  v-if="item.channelAvatar"
                  :src="item.channelAvatar"
                  alt=""
                />
                <span class="item-bottom-span1">{{ item.author }}</span>
              </div>
              <div class="content-item-text" v-html="item.description"></div>
              <div class="content-item-bottom">
                <span class="item-bottom-span2">
                  来源：
                  <a :href="item.url" target="_blank">{{ item.url }}</a>
                </span>
              </div>
            </div>
          </div>
          <li v-if="listData.length > 0">{{ nomoreText }}</li>
        </div>
      </div>
      <div class="video-right">
        <div>
          <div class="video-right-title">关键词方案</div>
          <div class="video-right-title-border"></div>
          <div class="video-right-record">
            <ul>
              <li v-for="(item, i) in planList" :key="i">
                <span
                  @click.prevent="selectKey(i, item)"
                  class="history-words"
                  :class="selectKeyIndex == i ? 'select-obj' : ''"
                >
                  <!--  -->
                  {{ item.groupName }}
                </span>
              </li>
            </ul>
            <no-data
              v-if="planList.length == 0"
              fontSize="14px"
              text="暂无关键词方案"
            />
          </div>
        </div>
        <div>
          <div class="video-right-title">近8条检索记录</div>
          <div class="video-right-title-border"></div>
          <div class="video-right-record">
            <ul>
              <li v-for="(item, i) in listRetrieval" :key="i">
                <span
                  @click.prevent="selectRecord(i, item)"
                  class="history-words"
                  :class="selectRecordIndex == i ? 'select-obj' : ''"
                >
                  <!--  -->
                  {{ item.searchKey }}
                  <img
                    class="close-log"
                    :src="require('@/assets/imgs/i11.png')"
                    alt=""
                    @click.stop="closeLog(item)"
                  />
                </span>
              </li>
            </ul>
            <no-data
              v-if="listRetrieval.length == 0"
              fontSize="14px"
              text="暂无检索记录"
            />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { videoList, logRead, dellogRead } from '@/api/channel.js'
import { keyWordsPage } from '@/api/keywords.js'
export default {
  data() {
    return {
      loading: false,
      planList: [],
      searchForm: {
        channelId: '',
        inputKey: ''
      },
      copySearchForm: {},
      // 当前页
      current: 1,
      // 每页记录数
      limit: 30,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: {},
      tagIndexObj: {},
      listData: [],
      selectRecordIndex: null,
      listRetrieval: [], //检索记录
      keyWords: '',
      //
      selectKeyIndex: null,
      //
      nomoreText: '加载中...',
      scrollDisabled: false
    }
  },

  created() {
    this.keyWords = this.$route.params.keyWords
    this.getPlan().then(arr => {
      if (this.keyWords) {
        if (arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (this.keyWords == arr[i].id) {
              this.selectKeyIndex = i
              this.copySearchForm = Object.assign(
                {},
                { userKeywordId: arr[i].id }
              )
            }
          }
        }
      }
    })
    if (!this.keyWords) {
      this.getVideoList()
    } else {
      videoList(this.current, this.limit, {
        userKeywordId: this.keyWords
      }).then(res => {
        this.loading = false
        this.total = res.all
        this.listData = res.items
      })
    }

    this.getlogSearch()
  },
  methods: {
    // 检索记录
    getlogSearch() {
      logRead().then(res => {
        this.listRetrieval = res
      })
    },

    // 查看新闻
    lookNews(row) {
      this.$router.push({
        path: '/video/detail/' + row.videoId,
        query: {
          videoTitle: row.title
        }
      })
    },
    searchArticlePage() {
      this.current = 1
      this.listData = []
      this.copySearchForm = Object.assign({}, this.searchForm)
      this.selectRecordIndex = null
      this.selectKeyIndex = null
      this.getVideoList()
      this.getlogSearch()
    },
    getVideoList() {
      this.loading = true
      this.scrollDisabled = true

      videoList(this.current, this.limit, this.copySearchForm).then(data => {
        this.loading = false
        this.total = data.all

        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    // 关键词数据
    getPlan() {
      return new Promise((resolve, reject) => {
        keyWordsPage(1, 1000).then(data => {
          //
          this.planList = data.items
          resolve(data.items)
        })
      })
    },

    currentChange(val) {
      this.current = val
      this.getVideoList()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getVideoList()
    },

    // 选择关键词
    selectKey(i, row) {
      if (row.id == this.copySearchForm.userKeywordId) {
        this.selectKeyIndex = null
        this.copySearchForm = {}
      } else {
        this.selectKeyIndex = i
        this.copySearchForm = Object.assign({}, { userKeywordId: row.id })
      }
      this.selectRecordIndex = null
      this.current = 1
      this.listData = []
      this.getVideoList()
    },

    // 选择检索记录
    selectRecord(i, row) {
      if (row.searchKey == this.copySearchForm.inputKey) {
        this.selectRecordIndex = null
        this.copySearchForm = {}
      } else {
        this.selectRecordIndex = i
        this.copySearchForm = Object.assign({}, { inputKey: row.searchKey })
      }

      this.selectKeyIndex = null
      this.current = 1
      this.listData = []
      this.getVideoList()
    },
    // 删除检索记录
    closeLog(row) {
      dellogRead(row.id).then(res => {
        this.$message({
          type: 'success',
          message: '已删除!'
        })
        this.selectRecordIndex = null
        this.getlogSearch()
      })
    }
  }
}
</script>

<style lang="scss">
.public-video {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .public-video-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 9px 0 rgb(82 63 105 / 18%);
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
    .head-tag-box-margin {
      margin: 10px 0 15px 15px;
    }
    .head-tag-box {
      .head-tag {
        padding: 7px 13px;
        background: rgb(241, 241, 241);
        border: 0px solid #000000;
        border-radius: 15px;
        border: 1px solid rgb(224, 224, 224);
        margin-right: 15px;
        user-select: none;
        cursor: pointer;
      }
      .select-tag {
        color: white;
        background: #131212;
        border: none;
      }
    }
  }
  .pagination-box {
    margin-bottom: 5px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 12px;
    color: #4b4b4b;
    background: #ffffff;
    border-radius: 5px;
  }
  .public-video-content {
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 5px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .video-left {
      width: calc(100% - 270px);
      height: 100%;

      .video-left-content {
        // border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding: 14px;
        background: #ffffff;
        width: 100%;
        height: calc(100% - 35px);
        overflow: hidden;
        overflow-y: auto;
        .content-item {
          width: 100%;
          border-bottom: 1px solid #e7e7e7;
          //   padding-bottom: 15px;
          margin-bottom: 20px;
          height: 188px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          .content-item-left {
            width: 336px;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content-item-right {
            width: calc(100% - 360px);
            height: 100%;
          }
          .content-item-title {
            font-size: 18px;
            font-weight: bold;
            color: #1a1a1b;
            position: relative;
            > .title {
              width: calc(100% - 0px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:hover {
                cursor: pointer;
                color: #66b1ff;
              }
            }
          }
          .content-item-time {
            font-size: 12px;
            font-weight: 300;
            color: #666666;
            margin: 15px 0;
          }
          .content-item-text {
            font-size: 12px;

            font-weight: 300;
            color: #444444;
            line-height: 18px;
            margin: 15px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .content-item-bottom {
            img {
              width: 24px;
              height: 24px;
              object-fit: cover;
              vertical-align: middle;
              border-radius: 50%;
              margin-right: 10px;
            }
            .item-bottom-span1 {
              font-size: 14px;
              margin-right: 15px;
              font-weight: 300;
              color: #666666;
            }
            .item-bottom-span2 {
              font-size: 12px;
              font-weight: 300;
              color: #1a1a1b;
              &:hover {
                color: #3c81db;
                a {
                  color: #3c81db;
                }
                text-decoration: underline;
              }
            }
          }
        }
        li {
          list-style: none;
          text-align: center;
          color: #555555;
          font-size: 12px;
        }
      }
    }
    .video-right {
      width: 260px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        border-radius: 5px;
        padding: 14px;
        width: 100%;
        height: 49.6%;
        overflow: auto;
        background: #ffffff;
        border: 1px solid #e5e5e5;
      }
      .video-right-title {
        padding-left: 10px;
        border-left: 4px solid #694638;
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
      }
      .video-right-title-border {
        border-bottom: 1px solid #e5e5e5ce;
        margin: 13px 0;
      }
      .video-right-record {
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        // background: #2a2a2a;
        padding-top: 10px;
        position: relative;
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin-bottom: 15px;
            .history-words {
              padding: 5px 10px;
              background: #efefef;
              border: 0px solid #000000;
              border-radius: 20px;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 300;
              color: #2a2a2a;
              cursor: pointer;
              user-select: none;
              position: relative;
              .close-log {
                position: absolute;
                top: -5px;
                right: -5px;
                display: none;
              }
              &:hover {
                .close-log {
                  display: block;
                }
              }
            }
            .select-obj {
              background: #292828;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  //
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
.video-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
</style>
